import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import { Suspense, lazy, useContext } from 'react';
import { AuthenticationContext, getLoginPageLocation } from '@chronosphereio/core';
import { AppWrapper } from './App';
import { Redirect } from './utils';
import { InitialLoadingPane } from '@/components';
import { AuthRoutes, PublicRoutes } from '@/model/Routes';
import { Login } from '@/views/login/Login';
import { LoginCallback } from '@/views/LoginCallback';
import { CliLoginCallback } from '@/views/CliLoginCallback';
import { LoginPkceCallback } from '@/views/LoginPkceCallback';
import { ResetPassword } from '@/views/login/ResetPassword';
import { AdminLogin } from '@/views/login/AdminLogin';
import { Logout } from '@/views/Logout';
import { LogoutCallback } from '@/views/LogoutCallback';
import { Invite as SignupInvite } from '@/views/signup/Invite';
import { SlackOAuthCallback } from '@/views/SlackOAuthCallback';
import { SlackOAuthSuccess } from '@/views/SlackOAuthSuccess';

const ProtectedApp = lazy(async () => {
  const { ProtectedApp } = await import('@/ProtectedApp');
  return { default: ProtectedApp };
});

const PublicDashboard = lazy(async () => {
  const { PublicDashboardPage } = await import('@/components/dashboards/PublicDashboard');
  return { default: PublicDashboardPage };
});

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const { isLoading, currentUser } = useContext(AuthenticationContext);

  // Show a loading screen until we know whether they are logged in
  if (isLoading) return <InitialLoadingPane />;

  // We know they aren't logged in, so redirect them to the login page
  if (currentUser === undefined) {
    const redirectLocation = getLoginPageLocation(AuthRoutes.LOGIN);
    return <Redirect to={redirectLocation} />;
  }

  // We know they are logged in, so let them into the rest of the app
  return children;
};

// export for testing
export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AppWrapper />}>
      <Route path={AuthRoutes.LOGIN} element={<Login />} />
      <Route path={AuthRoutes.LOGIN_CALLBACK} element={<LoginCallback />} />
      <Route path={AuthRoutes.LOGIN_PKCE_CALLBACK} element={<LoginPkceCallback />} />
      <Route path={AuthRoutes.IMPERSONATE} element={<AdminLogin />} />
      <Route path={AuthRoutes.IMPERSONATE_CALLBACK} element={<LoginCallback useImpersonation />} />
      <Route path={AuthRoutes.IMPERSONATE_PKCE_CALLBACK} element={<LoginPkceCallback useImpersonation />} />
      <Route path={AuthRoutes.RESET_PASSWORD} element={<ResetPassword />} />
      <Route path={AuthRoutes.LOGOUT} element={<Logout />} />
      <Route path={AuthRoutes.LOGOUT_CALLBACK} element={<LogoutCallback />} />
      <Route path={AuthRoutes.SIGNUP_INVITE} element={<SignupInvite />} />
      <Route path={AuthRoutes.SLACK_OAUTH_CALLBACK} element={<SlackOAuthCallback />} />
      <Route path={AuthRoutes.SLACK_OAUTH_SUCCESS} element={<SlackOAuthSuccess />} />
      <Route path={AuthRoutes.CLI_LOGIN} element={<Login fromCli />} />
      <Route path={AuthRoutes.CLI_LOGIN_CALLBACK} element={<CliLoginCallback />} />
      <Route path={AuthRoutes.CLI_IMPERSONATE} element={<AdminLogin fromCli />} />
      <Route path={AuthRoutes.CLI_IMPERSONATE_CALLBACK} element={<CliLoginCallback useImpersonation />} />
      <Route
        path={PublicRoutes.PUBLIC_DASHBOARD}
        element={
          <Suspense fallback={<InitialLoadingPane />}>
            <PublicDashboard />
          </Suspense>
        }
      />
      <Route
        path="*"
        element={
          <ProtectedRoute>
            <Suspense fallback={<InitialLoadingPane />}>
              <ProtectedApp />
            </Suspense>
          </ProtectedRoute>
        }
      />
    </Route>
  )
);

export const Router = () => {
  return <RouterProvider router={router} />;
};

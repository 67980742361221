import { Navigate, NavigateProps, useLocation } from 'react-router-dom';
import { trackRouteRedirection } from './monitoring/monitoring';
import { matchPaths } from './route-matching';

type RedirectProps = {
  to: NavigateProps['to'];
  path?: string | readonly string[];
};

/**
 * Takes a string redirect location that receives the matched route props and returns a redirect location
 */
export const Redirect = ({ path, to }: RedirectProps) => {
  const { pathname } = useLocation();
  const match = matchPaths(path, pathname);

  trackRouteRedirection({
    from: match?.pattern.path ?? '/',
    to: to.toString(),
  });

  return <Navigate to={to} replace />;
};

const CHRONO_PREVIEW_DEPLOYMENT_STORAGE_KEY = 'previewDeploySha';
const CHRONO_PREVIEW_DEPLOYMENT_TOGGLE_STORAGE_KEY = 'previewDeployToggle';
const CHRONO_PREVIEW_DEPLOYMENT_SHA_QUERY_PARAM = 'preview_sha';

/**
 * Clear the preview deployment from local storage and redirect to the current URL
 */
export function clearPreviewDeployment() {
  localStorage.removeItem(CHRONO_PREVIEW_DEPLOYMENT_STORAGE_KEY);
  const url = new URL(window.location.href);
  // Remove the sha from the URL before loading the page
  url.searchParams.delete(CHRONO_PREVIEW_DEPLOYMENT_SHA_QUERY_PARAM);
  window.location.href = url.toString(); // reload the page
}

/**
 * Returns whether the preview deployment toggle is on or off.
 * We store this in sessionStorage so that the toggle state is not lost when the page is reloaded.
 * This also allows us to have toggle state be independent for different tabs.
 */
export function getToggleState() {
  const val = sessionStorage.getItem(CHRONO_PREVIEW_DEPLOYMENT_TOGGLE_STORAGE_KEY);
  return val !== 'false'; // Only return false if the value is explicitly 'false'
}

/**
 * Set the preview deployment toggle state. Once set, the toggle state is stored in sessionStorage
 * and the page is reloaded to apply the changes.
 */
export function setToggleState(state: boolean) {
  sessionStorage.setItem(CHRONO_PREVIEW_DEPLOYMENT_TOGGLE_STORAGE_KEY, state.toString());
  const url = new URL(window.location.href);
  url.searchParams.delete(CHRONO_PREVIEW_DEPLOYMENT_SHA_QUERY_PARAM);
  // Reload the page to apply the changes
  window.location.href = url.toString();
}

// The duration in ms after which the preview deployment is considered expired
const LOCAL_STORAGE_EXPIRE_TIME_MS = 1000 * 60 * 10; // 10 minutes

/**
 * Get the preview deployment sha from the URL or local storage
 * NOTE:Only should be called once once the page loads
 */
function initializeAndGetPreviewDeploymentSha() {
  const urlSha = new URLSearchParams(window.location.search).get(CHRONO_PREVIEW_DEPLOYMENT_SHA_QUERY_PARAM);
  let localSha: string | null = null;
  const isToggledOn = getToggleState();
  if (urlSha) {
    // If the sha is in the URL, set the toggle state to true
    if (!isToggledOn) {
      setToggleState(true);
    }
    // write to local storage
    localStorage.setItem(CHRONO_PREVIEW_DEPLOYMENT_STORAGE_KEY, `${urlSha}:${Date.now()}`);
  } else {
    // If the sha is not in the URL, check if it's in local storage
    // If it is, reload the page with the sha in the URL
    // This is needed so nginx can route the request to the correct deployment
    const hasSha = localStorage.getItem(CHRONO_PREVIEW_DEPLOYMENT_STORAGE_KEY);
    if (hasSha) {
      const [sha, timestamp] = hasSha.split(':');
      localSha = sha;
      // If the sha was written to localStorage more than 10 minutes ago, this probably
      // means the preview deployment is old and has expired. In this case, we should clear
      // the preview deployment and reload the page with the latest deployed version.
      if (!timestamp || Date.now() - parseInt(timestamp, 10) > LOCAL_STORAGE_EXPIRE_TIME_MS) {
        clearPreviewDeployment();
        return;
      }
      const url = new URL(window.location.href);
      // If the toggle is on, set the sha in the URL and reload.
      if (isToggledOn) {
        url.searchParams.set(CHRONO_PREVIEW_DEPLOYMENT_SHA_QUERY_PARAM, sha);
        window.location.href = url.toString();
      }
    }
  }
  // Return the sha from the URL if it exists, otherwise return the sha from local storage
  return urlSha ?? localSha;
}

/**
 * The sha of the active preview deployment, this is initialized when the page loads
 */
export const ACTIVE_PREVIEW_DEPLOYMENT_SHA = initializeAndGetPreviewDeploymentSha();

/**
 * Boolean that returns true if the preview deployment is active
 */
export function isPreviewDeploymentActive() {
  return Boolean(ACTIVE_PREVIEW_DEPLOYMENT_SHA);
}

export function copyPreviewDeploymentLink(sha: string) {
  const url = new URL(window.location.href);
  if (!sha) {
    return;
  }
  url.searchParams.set(CHRONO_PREVIEW_DEPLOYMENT_SHA_QUERY_PARAM, sha);
  navigator.clipboard.writeText(url.toString()).then(() => {
    alert('Preview URL copied to clipboard.');
  });
}

export function urlContainsPreviewSha(urlPath: string) {
  try {
    return new URL(urlPath, window.location.origin).searchParams.has(CHRONO_PREVIEW_DEPLOYMENT_SHA_QUERY_PARAM);
  } catch (e) {
    return false;
  }
}

import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Blocker, BlockerFunction, useBlocker } from 'react-router-dom';

/**
 * @internal This should not be used directly, use the `NavigationBlockerContextProvider` instead
 */
export const NavigationBlockerContext = createContext<{
  blocker: Blocker;
  setIsBlocking: (arg: boolean) => void;
  setOnBlock: (onBlock: (() => void) | null) => void;
}>({
  blocker: {
    state: 'unblocked',
    proceed: undefined,
    reset: undefined,
    location: undefined,
  },
  setIsBlocking: () => {}, // noop
  setOnBlock: () => {}, // noop
});

export const NavigationBlockerContextProvider = (props: { children: React.ReactNode }) => {
  const [isBlocking, _setIsBlocking] = useState<boolean>(false);
  const [onBlock, _setOnBlock] = useState<(() => void) | null>(null);

  const setIsBlocking = useCallback((isBlocking: boolean) => _setIsBlocking(isBlocking), []);
  const setOnBlock = useCallback((onBlock: (() => void) | null) => _setOnBlock(onBlock), []);

  const shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => isBlocking && currentLocation.pathname !== nextLocation.pathname,
    [isBlocking]
  );
  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      onBlock?.();
    }
  }, [blocker.state, onBlock]);

  // alert users of unsaved changes if they close/refresh browser
  useEffect(() => {
    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      const returnString = 'Are you sure you want to leave the page?';
      event.returnValue = returnString;

      return returnString;
    };

    if (isBlocking) {
      window.addEventListener('beforeunload', onBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', onBeforeUnload);
    }

    return () => window.removeEventListener('beforeunload', onBeforeUnload);
  }, [isBlocking]);

  return (
    <NavigationBlockerContext.Provider
      value={{
        blocker,
        setIsBlocking,
        setOnBlock,
      }}
    >
      {props.children}
    </NavigationBlockerContext.Provider>
  );
};

export function useNavigationBlocker() {
  const { blocker, setIsBlocking, setOnBlock } = useContext(NavigationBlockerContext);
  return { blocker, setIsBlocking, setOnBlock };
}

import { useRef, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Collapse } from '@mui/material';
import { Alert } from '@chronosphereio/chrono-ui';
import { parseISO, isPast } from 'date-fns';
import { NotAuthenticatedError } from '@chronosphereio/core';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  InviteJson,
  SignupFormValues,
  SignupFormSchema,
  INVITE_SEARCH_PARAM,
  InviteRequestData,
  INVITE_REQUEST_URL,
  InviteMissingError,
  InviteExpiredError,
} from './signup-model';
import { SignupForm } from './SignupForm';
import { fetch } from '@/utils';
import { LogoPane } from '@/components';
import { useSnackbar } from '@/context/Snackbar';
import { AlertButton } from '@/chrono-ui-todos';
import { AuthRoutes } from '@/model/Routes';

/**
 * Processes link clicks from user invitation emails to allow the user to sign
 * up.
 */
export function Invite() {
  // Parse invite from the query string when this page initially loads
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const parsedInvite = useRef<InviteJson>();
  if (parsedInvite.current === undefined) {
    const invite = searchParams.get(INVITE_SEARCH_PARAM);
    if (invite === null) {
      throw new InviteMissingError();
    }
    const parsed: InviteJson = JSON.parse(atob(invite));
    parsedInvite.current = parsed;
  }

  const initialValues: SignupFormValues = {
    email: parsedInvite.current.email,
    name: '',
    company: parsedInvite.current.tenant?.name ?? '',
    password: '',
    confirmPassword: '',
  };

  // Check if invite expired already so we can show an initial error message
  // if it is
  const expired = isPast(parseISO(parsedInvite.current.expiresAt));
  if (expired) {
    throw new InviteExpiredError();
  }

  const { exceptionSnackbar } = useSnackbar();
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (values: SignupFormValues, helpers: FormikHelpers<SignupFormValues>) => {
    try {
      // This shouldn't happen, but make Typescript happy
      if (parsedInvite.current === undefined) {
        throw new Error('Missing parsed invite');
      }

      const body: InviteRequestData = {
        email: values.email,
        password: values.password,
        name: values.name,
        invite: parsedInvite.current.token,
      };

      await fetch(INVITE_REQUEST_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      setSuccess(true);
    } catch (err) {
      // 401s may contain an error message (e.g. invite already used)
      let errorMessage: string | undefined = undefined;
      if (err instanceof NotAuthenticatedError && err.response !== undefined) {
        try {
          const { error } = await err.response.json();
          if (error !== undefined) {
            errorMessage = error;
          }
        } catch (e) {
          console.error(e);
        }
      }

      exceptionSnackbar(new Error() /* avoid default 401 error messaging */, {
        severity: 'error',
        title: 'Failed to accept invite. Please try again later.',
        message: errorMessage,
      });
    } finally {
      helpers.setSubmitting(false);
    }
  };

  const goToLogin = () => navigate(AuthRoutes.LOGIN);

  return (
    <LogoPane>
      {/* Show a success message once successfully submitted */}
      <Collapse in={success === true}>
        <Alert severity="success" Action={<AlertButton onClick={goToLogin}>Go to Login</AlertButton>}>
          Successfully signed up!
        </Alert>
      </Collapse>

      <Collapse in={success === false}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={SignupFormSchema}
          validateOnBlur
          validateOnChange={false}
        >
          <SignupForm />
        </Formik>
      </Collapse>
    </LogoPane>
  );
}

import { matchPath } from 'react-router-dom';

// Allow path matching against multiple patterns, as that functionality was removed in react-router v6
export const matchPaths = (pattern: string | readonly string[] | undefined, pathname: string, exact?: boolean) => {
  if (!pattern) return null;
  const patterns = Array.isArray(pattern) ? pattern : [pattern];
  for (let i = 0; i < patterns.length; i += 1) {
    const match = matchPath({ path: patterns[i], end: exact ?? true }, pathname);
    if (match) return match;
  }
  return null;
};

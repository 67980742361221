import { useEffect, useState } from 'react';
import { useAuth, useSessionStorage } from '@chronosphereio/core';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Redirect } from '../utils';
import { LoadingPane } from '../components';
import { POST_LOGIN_REDIRECT_STORAGE_KEY, LOGIN_FROM_IDP_URL_PARAM } from './login/login-model';
import { AuthRoutes, RouteConstants } from '@/model/Routes';

export type CallbackProps = {
  useImpersonation?: boolean;
};

/**
 * View that processes login callbacks (i.e. redirects) from authentication.
 */
export function LoginPkceCallback({ useImpersonation }: CallbackProps = { useImpersonation: false }) {
  let verifyPrefix = '/auth/verify';
  let redirectRoute = AuthRoutes.LOGIN;
  if (useImpersonation) {
    verifyPrefix = '/auth/impersonate/verify';
    redirectRoute = AuthRoutes.IMPERSONATE;
  }

  const { refreshCurrentUser, currentUser, oktaClient } = useAuth();
  const navigate = useNavigate();
  const [redirectTo] = useSessionStorage<string | undefined>(POST_LOGIN_REDIRECT_STORAGE_KEY, undefined);
  const [accessToken, setAccessToken] = useState<undefined | string>();
  const [refreshToken, setRefreshToken] = useState<undefined | string>();
  const [verifyResponse, setVerifyResponse] = useState<undefined | Response>();

  // Parse params from the callback URL
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  const fromLogin = searchParams.get(LOGIN_FROM_IDP_URL_PARAM);

  if (code === null && fromLogin === null) {
    throw new Error('Invalid login callback.');
  }

  useEffect(() => {
    async function getToken() {
      const response = await oktaClient?.token.parseFromUrl();
      setAccessToken(response?.tokens.accessToken?.accessToken);
      setRefreshToken(response?.tokens.refreshToken?.refreshToken);
      return response;
    }
    getToken().catch((err) => {
      throw err;
    });
  }, [code, oktaClient, accessToken, refreshToken]);

  useEffect(() => {
    if (accessToken) {
      const verifyParams = new URLSearchParams();
      verifyParams.append('token', accessToken);
      fetch(`${verifyPrefix}?${verifyParams.toString()}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          refresh_token: refreshToken,
        }),
      }).then((response) => {
        setVerifyResponse(response);
      });
    }
  }, [accessToken, refreshToken, verifyPrefix]);

  useEffect(() => {
    if (verifyResponse !== undefined && refreshCurrentUser !== undefined) {
      refreshCurrentUser();
    }
  }, [verifyResponse, refreshCurrentUser]);

  useEffect(() => {
    if (currentUser !== undefined) {
      const nextUrl = redirectTo !== undefined ? redirectTo : RouteConstants.HOME;
      navigate(nextUrl, { replace: true });
    }
  }, [currentUser, navigate, redirectTo]);

  // If coming directly from the IDP to login, just redirect to login page
  // so it can log them in
  if (fromLogin !== null) {
    return <Redirect to={`${redirectRoute}?${searchParams.toString()}`} />;
  }

  return <LoadingPane text="Logging In" />;
}

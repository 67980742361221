import { useEffect, useState } from 'react';
import { FormControl, OutlinedInput, InputLabel } from '@mui/material';
import { useFetch, useAuth } from '@chronosphereio/core';
import { Alert, Box, Stack, Typography } from '@chronosphereio/chrono-ui';
import { useSearchParams } from 'react-router-dom';
import { CliLoginState, tenantFromHostname } from './login/login-model';
import { CliLoginAlert } from './login/CliLoginAlert';
import { AUTH_ERROR_HEADER } from '@/utils';
import { ExternalLink, LoadingPane, LogoPane, CopyToClipboardButton, Link } from '@/components';
import { AuthRoutes } from '@/model/Routes';

const TOKEN_CALLBACK_REGEX = /localhost:[0-9]+/;

export type CallbackProps = {
  useImpersonation?: boolean;
};

function useLoginParams() {
  const { oktaClient } = useAuth();

  // parse params
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const state = searchParams.get('state');

  // The Okta client generates and saves the state param for us during the initial auth flow
  // If state or transactionState does not exist, we are in a different auth flow. Only validate the match if both exist
  const transactionState = oktaClient?.storageManager?.getTransactionStorage().getItem('state');
  if (state !== undefined && transactionState !== undefined && state !== transactionState) {
    throw new Error('State in redirect uri does not match with transaction state');
  }

  if (code === null) {
    throw new Error('Invalid login code');
  }

  const parsedState = state ? (JSON.parse(state) as CliLoginState) : undefined;
  return { code, ...parsedState };
}

interface AuthVerifyResponse {
  verify: boolean;
  token: string;
}

function useAuthVerify(code: string, useImpersonation = false) {
  const verifyPrefix = useImpersonation ? '/auth/impersonate/verify' : '/auth/verify';

  const verifyParams = new URLSearchParams();
  verifyParams.append('token', code);
  verifyParams.append('cli', 'true');

  const [{ response: verifyResponse, data: verifyData, error: verifyError }] = useFetch<AuthVerifyResponse>(
    `${verifyPrefix}?${verifyParams.toString()}`
  );

  const impersonationError = verifyResponse?.headers.get(AUTH_ERROR_HEADER);
  if (impersonationError) {
    return { impersonationError };
  }

  if (verifyError !== undefined) {
    throw verifyError;
  }

  if (verifyData && !verifyData.verify) {
    throw new Error('Token not verified');
  }

  return {
    impersonationError,
    token: verifyData?.token,
  };
}

const WRITE_TIMEOUT_MS = 10000;

/*
 * https://stackoverflow.com/a/42189492/20382764
 * We check for Safari and Brave browser since it blocks writing to http://localhost
 * regardless of CSP settings. If Safari or Brave is detected we simply show the token
 * along with a copy-to-clipboard button.
 */
const browserRequiresCopyingToken =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).safari !== undefined ||
  // @ts-expect-error Brave detected via the method described in https://github.com/brave/brave-browser/wiki/Detecting-Brave-(for-Websites)
  (navigator.brave && navigator.brave.isBrave()) ||
  false;

function useWriteToken(token: string | undefined, tokenCallback: string | undefined) {
  const [writeSuccess, setWriteSuccess] = useState(false);
  const [writeError, setWriteError] = useState<unknown>();

  async function writeToken(token: string, tenant: string, tokenCallback: string) {
    try {
      if (!TOKEN_CALLBACK_REGEX.test(tokenCallback)) {
        // If the tokenCallback retrieved from the URL does not match, it is unsafe and we do not write it
        throw new Error('Callback server URL is invalid');
      }

      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), WRITE_TIMEOUT_MS);
      const response = await fetch('http://' + tokenCallback, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, tenant }),
        signal: controller.signal,
      });
      clearTimeout(timeoutId);
      if (response.ok) {
        setWriteSuccess(true);
      } else {
        throw new Error(`Response failed: ${response.status} ${response.statusText}`);
      }
    } catch (e) {
      setWriteError(e);
    }
  }

  useEffect(() => {
    if (token && tokenCallback && !browserRequiresCopyingToken) {
      writeToken(token, tenantFromHostname(window.location.hostname), tokenCallback);
    }
  }, [token, tokenCallback]);

  return { writeSuccess, writeError };
}

/**
 * View that processes login callbacks (i.e. redirects) from authentication.
 */
export function CliLoginCallback({ useImpersonation }: CallbackProps = { useImpersonation: false }) {
  const { code, tokenCallback } = useLoginParams();
  const { impersonationError, token } = useAuthVerify(code, useImpersonation);
  const { writeSuccess, writeError } = useWriteToken(token, tokenCallback);

  if (impersonationError) {
    return (
      <LogoPane>
        <Alert severity="error" sx={{ alignItems: 'center' }}>
          <Typography>
            <Stack spacing={0.5} component="span">
              <span>
                {impersonationError} For more information,{' '}
                <ExternalLink
                  color="inherit"
                  href="https://stackoverflowteams.com/c/chronosphere/questions/155"
                  rel="noopener noreferrer"
                >
                  go to StackOverflow.
                </ExternalLink>
              </span>
              <Link to={AuthRoutes.IMPERSONATE} color="inherit">
                Click to return to login
              </Link>
            </Stack>
          </Typography>
        </Alert>
      </LogoPane>
    );
  }

  if (writeError) {
    return (
      <LogoPane>
        <CliLoginAlert />
        <Alert severity="warning" sx={(theme) => ({ marginBottom: theme.spacing(4) })}>
          Unable to write session ID to callback server
        </Alert>
      </LogoPane>
    );
  }
  if (writeSuccess) {
    return (
      <LogoPane>
        <CliLoginAlert />
        <Box textAlign="center" marginTop={2}>
          <Typography>Session ID written to callback server - you may close the browser</Typography>
        </Box>
      </LogoPane>
    );
  }
  if (browserRequiresCopyingToken && token) {
    return (
      <LogoPane>
        <CliLoginAlert />
        <Stack direction="row" gap={1}>
          <FormControl fullWidth>
            <InputLabel>Session ID</InputLabel>
            <OutlinedInput label="Session ID" size="medium" value={chunkToken(token)} readOnly />
          </FormControl>
          <CopyToClipboardButton valueToCopy={chunkToken(token)} />
        </Stack>
        <Box textAlign="center" marginTop={2}>
          <Typography>You may close the browser after copying the session ID</Typography>
        </Box>
      </LogoPane>
    );
  }
  return <LoadingPane text="Logging In" />;
}

// chunkToken is used to break the session ID into several lines that can be pasted into the terminal.
// This is needed because terminal buffers generally only accept 1024 characters as stdin.
function chunkToken(token: string): string {
  const chunks: string[] = [];
  const chunkSize: number = 255;
  while (token.length > chunkSize) {
    chunks.push(token.substring(0, chunkSize));
    token = token.substring(chunkSize);
  }
  // Append `;` to the end of the string to signal to scripts that this is the end of the token
  chunks.push(token + ';');
  return chunks.join('\n');
}

import React from 'react';
// Embeds the VF time zone provider
// eslint-disable-next-line no-restricted-imports
import { TimeZoneProvider as VizFrameworkTimeZoneProvider } from '@chronosphereio/viz-framework';
// Passes the user's time zone to the VF time zone picker
// eslint-disable-next-line no-restricted-imports
import { useUserTimeZone } from '@/context/UserTimeZoneProvider';

export function TimeZoneProvider({ children }: { children: React.ReactNode }) {
  const { timeZone } = useUserTimeZone();
  return <VizFrameworkTimeZoneProvider userTimeZone={timeZone}>{children}</VizFrameworkTimeZoneProvider>;
}

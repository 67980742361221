import { useEffect, useRef } from 'react';
import {
  chronosphereLogsClient,
  FeatureFlagContextProvider,
  GatewayConfigsContextProvider,
} from '@chronosphereio/core';
import { useUnpinTimeRange } from '@chronosphereio/viz-framework';
import { useIsDarkMode } from '@chronosphereio/chrono-ui';
import { Outlet, useLocation } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { Provider as UrqlProvider } from 'urql';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { SnackbarProvider } from './context/Snackbar';
import { queryClient } from './query-client';
import { createUrqlClient, trackPageVisit } from '@/utils';
import { PageErrorBoundary } from '@/components';
import { ThemeContextProvider } from '@/context/ThemeContextProvider';
import { ActiveResourceProvider } from '@/context/ActiveResourceContext';
import { CloudChartsProvider } from '@/context/CloudChartsProvider';
import { UserTimeZoneProvider } from '@/context/UserTimeZoneProvider';
import { AnalyticsProvider } from '@/context/analytics/Analytics';
import { AppHeaderProvider } from '@/context/AppHeaderContext';
import { CloudAuthProvider } from '@/context/CloudAuthProvider';
import { NavigationBlockerContextProvider } from '@/context/NavigationBlockerContext';
import { ChronoAdminProvider } from '@/admin';
import { ModalProvider } from '@/components/modal';
import { TimeZoneProvider } from '@/viz-framework';

export const APPLICATION_ID = 'root';

const App = () => {
  const isDarkMode = useIsDarkMode();
  const location = useLocation();
  const prevPathnameRef = useRef(location.pathname);

  // Unpin the time range when a tab is closed
  useUnpinTimeRange();

  useEffect(() => {
    const rootElement = document.getElementById(APPLICATION_ID);

    if (rootElement) {
      rootElement.style.colorScheme = isDarkMode ? 'dark' : 'light';
    }
  }, [isDarkMode]);

  useEffect(() => {
    if (location.pathname !== prevPathnameRef.current) {
      chronosphereLogsClient.page();
      trackPageVisit(prevPathnameRef.current, location.pathname);
      prevPathnameRef.current = location.pathname;
    }
  }, [location]);

  return <Outlet />;
};

export const AppWrapper = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <UrqlProvider value={createUrqlClient()}>
        <QueryParamProvider adapter={ReactRouter6Adapter} options={{ includeAllParams: true, params: {} }}>
          <CloudAuthProvider>
            <UserTimeZoneProvider>
              <FeatureFlagContextProvider>
                <ThemeContextProvider applicationRoot={`#${APPLICATION_ID}`}>
                  <SnackbarProvider>
                    <GatewayConfigsContextProvider>
                      <AnalyticsProvider>
                        <ActiveResourceProvider>
                          <CloudChartsProvider>
                            <TimeZoneProvider>
                              <AppHeaderProvider>
                                <PageErrorBoundary>
                                  <ChronoAdminProvider>
                                    <ModalProvider>
                                      <NavigationBlockerContextProvider>
                                        <App />
                                      </NavigationBlockerContextProvider>
                                    </ModalProvider>
                                  </ChronoAdminProvider>
                                </PageErrorBoundary>
                              </AppHeaderProvider>
                            </TimeZoneProvider>
                          </CloudChartsProvider>
                        </ActiveResourceProvider>
                      </AnalyticsProvider>
                    </GatewayConfigsContextProvider>
                  </SnackbarProvider>
                </ThemeContextProvider>
              </FeatureFlagContextProvider>
            </UserTimeZoneProvider>
          </CloudAuthProvider>
        </QueryParamProvider>
      </UrqlProvider>
    </QueryClientProvider>
  );
};

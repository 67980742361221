import { createRoot } from 'react-dom/client';
import { initializeErrorTracking, appendUserGuidingScript } from '@chronosphereio/core';
import { APPLICATION_ID } from './App';
import { Router } from './Router';
import { initNetworkMonitoring, setMuiXProLicenseKey } from '@/utils';
import { isPreviewDeploymentActive } from '@/admin';

// Only initialize error tracking if we're not in a preview deployment
if (!isPreviewDeploymentActive()) {
  initializeErrorTracking();
  initNetworkMonitoring();
}

setMuiXProLicenseKey();
appendUserGuidingScript();

const rootEl = document.getElementById(APPLICATION_ID);
if (rootEl === null) {
  throw new Error('Root relement for react rendering not found');
}

const root = createRoot(rootEl);
root.render(<Router />);

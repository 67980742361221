// eslint-disable-next-line no-restricted-imports
import { FullStory, init as initFullStory } from '@fullstory/browser';
import { FULL_STORY_ORG_ID } from '@chronosphereio/shared';
import { logError, AnalyticsInternal, AnalyticsUserData, EventPayload } from '@chronosphereio/core';

const FS_RELAY_HOST = 'fsrelay.chronosphere.io';

/**
 * Analytics client that reports events to FullStory.
 */
export class FullStoryAnalytics implements AnalyticsInternal {
  static isInitialized = false;
  static useRelay = false;

  constructor(useRelay = false) {
    FullStoryAnalytics.useRelay = useRelay;
  }

  getSessionUrl() {
    try {
      const session = FullStory('getSession', { format: 'url.now' });

      if (session) {
        return session.replace(FS_RELAY_HOST, 'fullstory.com');
      }
    } catch (err) {
      logError(err);
    }
  }

  track(eventName: string, payload: EventPayload = {}) {
    try {
      // For more information on FullStory events, see:
      // https://developer.fullstory.com/browser/capture-events/analytics-events/
      FullStory('trackEvent', {
        name: eventName,
        properties: payload,
      });
    } catch (err) {
      logError(err);
    }
  }

  trackMetric(eventName: string, labels: EventPayload = {}) {
    try {
      // For more information on FullStory events, see:
      // https://developer.fullstory.com/browser/capture-events/analytics-events/
      FullStory('trackEvent', {
        name: eventName,
        properties: labels,
      });
    } catch (err) {
      logError(err);
    }
  }

  setAdditionalUserContext(context: AnalyticsUserData): void {
    try {
      // For more information on FullStory user properties, see:
      // https://developer.fullstory.com/browser/identification/set-user-properties/
      FullStory('setProperties', {
        type: 'user',
        properties: context,
      });
    } catch (err) {
      logError(err);
    }
  }

  initialize(): void {
    // Class is meant to be used like a singleton, but guard against multiple initializations
    if (FullStoryAnalytics.isInitialized) {
      console.error('Received multiple calls to initialize FullStoryAnalytics');
      return;
    }

    // No try...catch here to allow consumer to know about and log errors if we can't initialize
    initFullStory({
      orgId: FULL_STORY_ORG_ID,
      devMode: false,
      debug: false,
      host: FullStoryAnalytics.useRelay === true ? FS_RELAY_HOST : undefined,
      script: FullStoryAnalytics.useRelay === true ? `${FS_RELAY_HOST}/s/fs.js` : undefined,
    });
    FullStoryAnalytics.isInitialized = true;
  }
}

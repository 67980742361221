import { Form } from 'formik';
import { OutlinedInput, InputLabel, FormControl, FormHelperText, Collapse } from '@mui/material';
import { Box, Alert, Button, DEPRECATED_REACT_PROPS } from '@chronosphereio/chrono-ui';
import { useNavigate } from 'react-router-dom';
import { ResetPasswordFormValues } from './login-model';
import { useInput, useSubmitButton } from '@/utils';
import { AuthRoutes } from '@/model/Routes';

export interface ResetPasswordFormProps {
  success: boolean;
  serverError?: string;
}

export function ResetPasswordForm(props: ResetPasswordFormProps) {
  const { success, serverError } = props;

  const email = useInput<ResetPasswordFormValues['email']>('email');
  const submitButton = useSubmitButton();

  const navigate = useNavigate();
  const goToLogin = () => navigate(AuthRoutes.LOGIN);

  return (
    <Form {...DEPRECATED_REACT_PROPS}>
      {/* Show a success message once successfully submitted */}
      <Collapse in={success === true}>
        <Alert
          severity="success"
          Action={
            <Button color="inherit" onClick={goToLogin}>
              Go to Login
            </Button>
          }
        >
          If there is a user associated with that email, a password reset email was sent.
        </Alert>
      </Collapse>

      {/* Show the form controls when not successfully submitted */}
      <Collapse in={success === false}>
        <Collapse in={serverError !== undefined}>
          <Alert severity="error">{serverError}</Alert>
        </Collapse>

        <FormControl {...email.formControl} margin="normal" fullWidth>
          <InputLabel {...email.inputLabel}>Email</InputLabel>

          <OutlinedInput {...email.input} label="Email" size="medium" />

          <FormHelperText {...email.formHelperText} />
        </FormControl>

        <Box marginTop={4}>
          <Button {...submitButton} fullWidth>
            Reset Password
          </Button>
        </Box>
      </Collapse>
    </Form>
  );
}

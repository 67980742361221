import { useEffect } from 'react';
import { Typography } from '@mui/material';
import { Alert, Stack } from '@chronosphereio/chrono-ui';
import { useAuth, useSessionStorage } from '@chronosphereio/core';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { POST_LOGIN_REDIRECT_STORAGE_KEY, LOGIN_FROM_IDP_URL_PARAM } from './login/login-model';
import { AUTH_ERROR_HEADER, Redirect, useLazyFetch } from '@/utils';
import { Link, ExternalLink, LoadingPane, LogoPane } from '@/components';
import { AuthRoutes, RouteConstants } from '@/model/Routes';
import { urlContainsPreviewSha } from '@/admin/preview-deployments/model';
export type CallbackProps = {
  useImpersonation?: boolean;
};

/**
 * View that processes login callbacks (i.e. redirects) from authentication.
 */
export function LoginCallback({ useImpersonation }: CallbackProps = { useImpersonation: false }) {
  let verifyPrefix = '/auth/verify';
  let redirectRoute = AuthRoutes.LOGIN;

  if (useImpersonation) {
    verifyPrefix = '/auth/impersonate/verify';
    redirectRoute = AuthRoutes.IMPERSONATE;
  }

  const navigate = useNavigate();
  const { refreshCurrentUser, currentUser, oktaClient } = useAuth();

  const [redirectTo] = useSessionStorage<string | undefined>(POST_LOGIN_REDIRECT_STORAGE_KEY, undefined);

  // Parse params from the callback URL
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const state = searchParams.get('state');
  const fromLogin = searchParams.get(LOGIN_FROM_IDP_URL_PARAM);
  // The Okta client generates and saves the state param for us during the initial auth flow
  const transactionState = oktaClient?.storageManager?.getTransactionStorage().getItem('state');

  // If state or transactionState does not exist, we are in a different auth flow. Only validate the match if both exist
  if (state !== undefined && transactionState !== undefined && state !== transactionState) {
    throw new Error('State in redirect uri does not match with transaction state');
  }

  if (code === null && fromLogin === null) {
    throw new Error('Invalid login callback.');
  }

  // If we're doing an SSO callback, this is how it's verified
  const verifyParams = new URLSearchParams();
  if (code !== null) {
    verifyParams.append('token', code);
  }

  const [{ response: verifyResponse, error: verifyError }, verify] = useLazyFetch(
    `${verifyPrefix}?${verifyParams.toString()}`
  );

  const impersonationError = verifyResponse?.headers.get(AUTH_ERROR_HEADER);

  if (verifyError !== undefined) {
    throw verifyError;
  }

  // Kick off SSO verify if we have a code
  useEffect(() => {
    if (code !== null) {
      verify();
    }
  }, [code, verify]);

  // Refresh the user and send them home if we get a successful verify response
  useEffect(() => {
    if (verifyResponse !== undefined && refreshCurrentUser !== undefined) {
      refreshCurrentUser();
    }
  }, [verifyResponse, refreshCurrentUser]);

  useEffect(() => {
    if (currentUser !== undefined && verifyResponse !== undefined) {
      const nextUrl = redirectTo !== undefined ? redirectTo : RouteConstants.HOME;
      if (nextUrl && urlContainsPreviewSha(nextUrl)) {
        // Since we are redirecting to a preview deployment,
        // we need to reload the page to get the correct deployment
        window.location.href = nextUrl;
      } else {
        navigate(nextUrl, { replace: true });
      }
    }
  }, [currentUser, navigate, redirectTo, verifyResponse]);

  if (impersonationError) {
    return (
      <LogoPane>
        <Alert severity="error" sx={{ alignItems: 'center' }}>
          <Typography>
            <Stack spacing={0.5} component="span">
              <span>
                {impersonationError} For more information,{' '}
                <ExternalLink
                  color="inherit"
                  href="https://stackoverflowteams.com/c/chronosphere/questions/155"
                  rel="noopener noreferrer"
                >
                  go to StackOverflow.
                </ExternalLink>
              </span>
              <Link to={AuthRoutes.IMPERSONATE} color="inherit">
                Click to return to login
              </Link>
            </Stack>
          </Typography>
        </Alert>
      </LogoPane>
    );
  }

  // If coming directly from the IdP to login, just redirect to login page
  // so it can log them in
  if (fromLogin !== null) {
    return <Redirect to={`${redirectRoute}?${searchParams.toString()}`} />;
  }

  return <LoadingPane text="Logging In" />;
}

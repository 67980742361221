import { differenceInMilliseconds, isDate } from 'date-fns';
import { useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';
import prom, { Labels } from 'promjs';
import { BootFlags, getBootFlag, getTenantNamespace, logError } from '@chronosphereio/core';
import { getAllRoutePaths } from '../paths';
import { dispatchPageTransitionEvent } from '../tracing/traceEvents';
import { isPreviewDeploymentActive } from '@/admin/preview-deployments/model';
/**
 * Checks if metrics have changed and send them to the API when they have
 */
export function usePrometheusMonitoring() {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sendMetrics = useCallback(debounce(sendMetricsToPromGateway, 500), []);

  /**
   * Listen for all errors and record it for error tracking purposes
   */
  useEffect(() => {
    const globalErrorHandler = () => {
      // Don't send error message because it will create too many unique metrics
      trackUncaughtError();
    };
    window.addEventListener('error', globalErrorHandler);
    return () => window.removeEventListener('error', globalErrorHandler);
  }, []);

  /**
   * Record initial page load time by referencing variable set on the window
   */
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const startTime: Date | undefined = (window as any).CHRONOSPHERE_INITIALIZE_LOAD_START_TIME;
    if (startTime && isDate(startTime)) {
      trackPageLoad(differenceInMilliseconds(new Date(), startTime));
    }
  }, []);

  /**
   * send string to API whenever changes occur
   */
  useEffect(() => {
    /**
     * Use event bus to listen for Prom tracking calls and send to API
     */
    monitoringEventBus.addEventListener(PROM_METRIC_TRACKED_EVENT, sendMetrics);
    sendMetrics();
    return () => monitoringEventBus.removeEventListener(PROM_METRIC_TRACKED_EVENT, sendMetrics);
  }, [sendMetrics]);
}

export const promRegistry = prom();

export const PROM_METRIC_TRACKED_EVENT = 'PROM_METRIC_TRACKED';
export const monitoringEventBus = new EventTarget();
export const cloudUIMetricPrefix = 'cloudui_application_';

/**
 * Default buckets for histograms which are typically dealing with latency. Note: our UI Prom library *will not* create
 * any buckets by default, so you need to pass a bucket config (like this) when creating histograms.
 *
 * (Values taken from `prom-client` for Node.js: https://github.com/siimon/prom-client/blob/master/lib/histogram.js)
 */
const DEFAULT_HISTOGRAM_BUCKETS = [0.005, 0.01, 0.025, 0.05, 0.1, 0.25, 0.5, 1, 2.5, 5, 10];

/**
 * Same as DEFAULT_HISTOGRAM_BUCKETS, but values in ms instead of seconds.
 */
const DEFAULT_HISTOGRAM_BUCKETS_MS = DEFAULT_HISTOGRAM_BUCKETS.map((val) => val * 1000);

export const getPageUrl = (path: string) => {
  const allRouteUrls = getAllRoutePaths();

  // Only use the base of the URL to avoid unique ids creating high cardinality Prom metrics
  const basePath = path.split('/')[1];

  // If url is a match of a url, use the full url
  /**
   * If the url path is an exact match of a route, use it so we get more detail
   * But if it has an ID or other unique thing in the path, only use the first path to avoid too many unique labels
   */
  return allRouteUrls.includes(path) ? path : basePath;
};

/**
 * A set of prometheus labels we want to associate with every metric
 */
export function getDefaultPrometheusLabels() {
  const pageUrl = getPageUrl(location.pathname);
  return {
    page_url: pageUrl,
    browserName: BrowserDetect(),
    namespace: getTenantNamespace(),
    app_name: 'cloud-ui',
    // The "service" label is used for internal cost accounting.
    service: 'cloud-ui',
  };
}

/**
 * Wraps our metric tracking to prevent any errors from breaking application code
 * AND dispatches an event to let the Prom event bus know it should send new metrics to API
 */
export function wrapTrackingFunction<T extends unknown[]>(trackFn: (...params: T) => void) {
  return (...params: T) => {
    return setTimeout(() => {
      try {
        trackFn(...params);
        monitoringEventBus.dispatchEvent(new Event(PROM_METRIC_TRACKED_EVENT));
      } catch (error) {
        // Prevent tracking errors from blowing up the app
        logError(error);
      }
    }, 0);
  };
}

const pageLoadTimer = promRegistry.create(
  'histogram',
  'cloudui_page_load_time_ms',
  'A metric to measure the time to load scripts in UI',
  DEFAULT_HISTOGRAM_BUCKETS_MS
);

const pageVisitCounter = promRegistry.create(
  'counter',
  'cloudui_page_visits_total',
  'A counter for tracking page visits'
);

const requestLatencies = promRegistry.create(
  'histogram',
  'cloudui_request_ms',
  'A metric for UI HTTP request latency',
  DEFAULT_HISTOGRAM_BUCKETS_MS
);

const pageRenderErrorCounter = promRegistry.create(
  'counter',
  'cloudui_react_render_error_total',
  'A counter for React rendering errors'
);

const uncaughtErrorCounter = promRegistry.create(
  'counter',
  'cloudui_application_uncaught_error_total',
  'A counter for uncaught JS errors'
);

const caughtErrorCounter = promRegistry.create(
  'counter',
  'cloudui_application_caught_error_total',
  'A counter for caught JS errors'
);

const uiQueryServiceErrorCounter = promRegistry.create(
  'counter',
  'cloudui_application_ui_query_service_error_total',
  'A counter for rest api errors'
);

const alertHistoryPageCounter = promRegistry.create(
  'counter',
  'cloudui_application_alert_history',
  'A counter for alert history events'
);

const routeRedirectionCounter = promRegistry.create(
  'counter',
  'cloudui_application_route_redirection_counter',
  'A counter to display redirects to a new route'
);

const doorbellInitializationFailuresCounter = promRegistry.create(
  'counter',
  'cloudui_application_doorbell_init_failure_total',
  'A counter failed doorbell feedback script initialization failures'
);

const notificationReceiverSourceCounter = promRegistry.create(
  'counter',
  'cloudui_application_notification_receiver_source_total',
  'A counter to display the receiver that was used to open the notification'
);

const changeEventsTotal = promRegistry.create(
  'counter',
  'cloudui_application_change_events_total',
  'A counter for total change events for a time period'
);

const changeEventsShown = promRegistry.create(
  'counter',
  'cloudui_application_change_events_shown_total',
  'A counter for change events shown for a time period'
);

const uiAnalytics = promRegistry.create(
  'counter',
  'cloudui_track_analytics_call_total',
  'A counter for tracking analytics API calls'
);

const fpsHistogram = promRegistry.create(
  'histogram',
  'cloudui_fps',
  'A histogram to measure the observed frame rate of cloud ui during interactivity',
  [0, 15, 30, 60, 120]
);

const requestsMsHistogram = promRegistry.create(
  'histogram',
  'cloudui_network_ms',
  'A histogram to measure network requests made from the browser',
  DEFAULT_HISTOGRAM_BUCKETS_MS
);

export const trackFps = ({ pageKey, fps }: { pageKey: string; fps: number }) => {
  fpsHistogram.observe(fps, {
    page_key: pageKey,
    ...getDefaultPrometheusLabels(),
  });
};

export const trackAlertHistoryPageEvents = wrapTrackingFunction(({ event_type }: { event_type: string }) => {
  alertHistoryPageCounter.inc({
    event_name: 'alert_history',
    event_type: event_type,
    ...getDefaultPrometheusLabels(),
  });
});

export const trackUncaughtError = wrapTrackingFunction(() => uncaughtErrorCounter.inc(getDefaultPrometheusLabels()));

export const trackCaughtError = wrapTrackingFunction(
  ({ error_source, error_type }: { error_source: string; error_type: string }) =>
    caughtErrorCounter.inc({
      error_source: error_source,
      error_type: error_type,
      ...getDefaultPrometheusLabels(),
    })
);

export const trackAnalyticsAPICall = wrapTrackingFunction(({ event_name, ...rest }: { event_name: string }) =>
  uiAnalytics.inc({
    event_name: event_name,
    ...rest,
    ...getDefaultPrometheusLabels(),
  })
);

export const trackRestApiError = wrapTrackingFunction(
  ({ error_source, error_type }: { error_source: string; error_type: string }) =>
    uiQueryServiceErrorCounter.inc({
      error_source: error_source,
      error_type: error_type,
      ...getDefaultPrometheusLabels(),
    })
);

export const trackPageLoad = wrapTrackingFunction((value: number) =>
  pageLoadTimer.observe(value, getDefaultPrometheusLabels())
);

export const trackPageVisit = wrapTrackingFunction((prevPathname: string, pathname: string) => {
  dispatchPageTransitionEvent(prevPathname, pathname);

  return pageVisitCounter.inc(getDefaultPrometheusLabels());
});

export const trackRequestLatency = wrapTrackingFunction(
  (
    startTime: Date,
    { request_url, status, gql_query_name }: { request_url: URL; status: number; gql_query_name: string | undefined }
  ) => {
    // Only track request latency for requests to our domain
    if (request_url.origin !== window.location.origin) {
      return;
    }

    const endTime = new Date();
    const requestLatency = differenceInMilliseconds(endTime, startTime);
    const labels: Record<string, string | number> = {
      status,
      // Remove any query string from URL to avoid high cardinality on url
      request_url: request_url.pathname,
      ...getDefaultPrometheusLabels(),
    };

    if (gql_query_name !== undefined) {
      labels.gql_query_name = gql_query_name;
    }
    requestLatencies.observe(requestLatency, labels);
  }
);

/**
 * Observes all Cloud UI fetch requests and records response time into histogram
 *
 * @returns disconnect function that will discontinue monitoring when invoked
 */
export const initNetworkMonitoring = () => {
  try {
    if (getBootFlag(BootFlags.NETWORK_MONITORING_ENABLED) !== 'true') return;

    const observer = new PerformanceObserver((list) => {
      list.getEntries().forEach((entry) => {
        const timing = entry as PerformanceResourceTiming;
        // Don't record GQL requests here-- we already do that elsewhere:
        if (timing.initiatorType === 'fetch' && !timing.name.match(/gql/)) {
          const url = new URL(timing.name);
          const latency = timing.responseEnd - timing.requestStart;
          const path = url.pathname;

          requestsMsHistogram.observe(latency, {
            request_path: path,
            ...getDefaultPrometheusLabels(),
          });
        }
      });
    });

    observer.observe({ type: 'resource', buffered: true });

    return observer.disconnect;
  } catch {
    // If this fails, don't worry about it, it's just instrumentation.
  }
};

export const trackPageRenderErrors = wrapTrackingFunction((labels: Labels = {}) => {
  pageRenderErrorCounter.inc({ ...labels, ...getDefaultPrometheusLabels() });
});

/**
 * Before incrementing trackRouteRedirection metric, we need to trim away any query params
 * since the query param could be very long
 */
export function trimQueryParams(path: string) {
  return path.split('?')[0];
}

export const trackRouteRedirection = wrapTrackingFunction(({ from, to }: { from: string; to: string }) => {
  routeRedirectionCounter.inc({
    ...getDefaultPrometheusLabels(),
    from: trimQueryParams(from),
    to: trimQueryParams(to),
  });
});

export const trackReceiverSource = wrapTrackingFunction(
  ({ receiverType, receiverName }: { receiverType: string; receiverName: string }) => {
    notificationReceiverSourceCounter.inc({
      ...getDefaultPrometheusLabels(),
      receiverType,
      receiverName,
    });
  }
);

export const trackChangeEventsTotal = wrapTrackingFunction(
  ({ totalEvents, timeRangeMillis }: { totalEvents: number; timeRangeMillis: number }) => {
    changeEventsTotal.add(totalEvents, { timeRangeMillis, ...getDefaultPrometheusLabels() });
  }
);

export const trackChangeEventsShown = wrapTrackingFunction(
  ({ eventsShown, timeRangeMillis }: { eventsShown: number; timeRangeMillis: number }) => {
    changeEventsShown.add(eventsShown, { timeRangeMillis, ...getDefaultPrometheusLabels() });
  }
);

export const trackDoorbellInitializationFailed = wrapTrackingFunction(() =>
  doorbellInitializationFailuresCounter.inc(getDefaultPrometheusLabels())
);

const tracingEventsCounter = promRegistry.create(
  'counter',
  'cloudui_tracing_events_counter',
  'A counter to track various Tracing UI interactions.'
);

export const trackTracingEvent = wrapTrackingFunction(
  ({ feature, value, details }: { feature: string; value?: string | number; details?: string | number }) => {
    tracingEventsCounter.inc({
      feature,
      ...getDefaultPrometheusLabels(),
      ...(value === undefined ? undefined : { value }),
      ...(details === undefined ? undefined : { details }),
    });
  }
);

const tracingTimelineResultsHistogram = promRegistry.create(
  'histogram',
  'cloudui_tracing_timeline_results',
  'A histogram to measure buckets of numeric values in trace search responses',
  [0, 10_000, 100_000, 250_000, 500_000, 1_000_000]
);

export const trackTracingTimelineResultsHistogram = wrapTrackingFunction(
  ({ field, value, idx }: { field: string; value: number; idx?: number }) =>
    tracingTimelineResultsHistogram.observe(value, {
      field,
      fieldIndex: idx ?? 0,
      ...getDefaultPrometheusLabels(),
    })
);

const tracingSearchHistogram = promRegistry.create(
  'histogram',
  'cloudui_tracing_search_histogram',
  'A histogram to measure buckets of numeric values in trace search parameters',
  [0, 1, 2, 5, 10, 15, 20]
);

export const trackTracingSearchHistogram = wrapTrackingFunction(
  ({ field, value, idx }: { field: string; value: number; idx?: number }) =>
    tracingSearchHistogram.observe(value, {
      field,
      fieldIndex: idx ?? 0,
      ...getDefaultPrometheusLabels(),
    })
);

const tracingSearchCount = promRegistry.create(
  'counter',
  'cloudui_tracing_search_counter',
  'A counter to track Tracing UI searches'
);

export const trackTracingSearch = wrapTrackingFunction(
  ({ field, value, idx }: { field: string; value: string | number | undefined; idx?: number }) => {
    tracingSearchCount.inc({
      fieldName: field,
      fieldValue: value ?? '',
      fieldIndex: idx ?? 0,
      ...getDefaultPrometheusLabels(),
    });
  }
);

export const METRICS_GATEWAY_ENDPOINT = '/prom-agg-gateway/metrics/';

export async function sendMetricsToPromGateway() {
  // Disable monitoring for local development
  if (process.env.NODE_ENV === 'development' || isPreviewDeploymentActive()) {
    return;
  }

  const metrics = promRegistry.metrics();
  // Use native fetch to avoid infinite loops because our custm Fetch Util uses trackingexpo code
  const response = await window.fetch(METRICS_GATEWAY_ENDPOINT, {
    method: 'POST',
    headers: { 'Content-Type': 'text/plain;charset=UTF-8' },
    body: metrics,
  });
  if (response.ok) {
    /**
     * According to docs, reset the counts of metrics after reporting to aggregator
     * https://github.com/weaveworks/promjs/blob/master/README.md#registryreset--self
     */
    promRegistry.reset();
  }
}

// For reference this was source:
// https://stackoverflow.com/questions/34142711/javascript-how-do-i-get-all-the-browsers-name
export const BrowserDetect = function () {
  const nav = window.navigator,
    ua = window.navigator.userAgent.toLowerCase();
  // Detect browsers (only the ones that have some kind of quirk we need to work around)
  if (nav.appName.toLowerCase().indexOf('microsoft') !== -1 || nav.appName.toLowerCase().match(/trident/gi) !== null)
    return 'IE';
  if (ua.match(/chrome/gi) !== null) return 'Chrome';
  if (ua.match(/firefox/gi) !== null) return 'Firefox';
  if (ua.match(/safari/gi) !== null) return 'Safari';
  if (ua.match(/webkit/gi) !== null) return 'Webkit';
  if (ua.match(/gecko/gi) !== null) return 'Gecko';
  if (ua.match(/opera/gi) !== null) return 'Opera';
  if (ua.match(/ui-prober/gi) !== null) return 'Prober';

  // If any case miss we will return null
  return 'unknown';
};

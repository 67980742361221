import { Alert } from '@chronosphereio/chrono-ui';
import { useNavigate } from 'react-router-dom';
import { LogoPane } from '../components';
import { AlertButton } from '@/chrono-ui-todos';
import { AuthRoutes } from '@/model/Routes';

/**
 * View that the user is redirected to after a successful logout.
 */
export function LogoutCallback() {
  const navigate = useNavigate();
  const goToLogin = () => navigate(AuthRoutes.LOGIN);

  return (
    <LogoPane>
      <Alert severity="success" Action={<AlertButton onClick={goToLogin}>Login Again</AlertButton>}>
        You have been successfully logged out.
      </Alert>
    </LogoPane>
  );
}

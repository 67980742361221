import { useCallback, useMemo } from 'react';
import constate from 'constate';
import {
  BootFlags,
  getBootFlag,
  logError,
  chronosphereLogsClient,
  Analytics,
  AnalyticsInternal,
} from '@chronosphereio/core';
import { LocalDevAnalytics } from './local-dev-analytics';
import { NoOpAnalytics } from './no-op-analytics';
import { FullStoryAnalytics } from './fullstory-analytics';
import { useAddUserContextToAnalytics } from './add-user-context';
import { trackUiAnalytics } from '@/utils/monitoring';

function useAnalyticsStore(): Analytics {
  // Init the analytics client when the provider mounts
  const client = getAnalyticsClient();

  // Once the user is logged in, we can add some additional information about them
  useAddUserContextToAnalytics(client);

  // Wrap some client methods so that the context object returned can potentially be destructured
  const track: Analytics['track'] = useCallback(
    (...args) => {
      trackUiAnalytics({
        // For the base track method, only track the event name to avoid unexpected cardinality
        event_name: args[0],
      });
      chronosphereLogsClient.track(...args);
      return client.track(...args);
    },
    [client]
  );

  const trackMetric: Analytics['trackMetric'] = useCallback(
    (...args) => {
      trackUiAnalytics({
        event_name: args[0],
        // Pass through any additional labels the caller has provided
        ...args[1],
      });
      chronosphereLogsClient.track(...args);
      return client.track(...args);
    },
    [client]
  );

  // Memoize in case entire object is used in an effect/callback
  return useMemo(() => ({ track, trackMetric, getSessionUrl: client.getSessionUrl }), [track, trackMetric, client]);
}

export const [AnalyticsProvider, useAnalytics] = constate(useAnalyticsStore);

// Singleton instance of the analytics client
let client: AnalyticsInternal | undefined = undefined;

function getAnalyticsClient(): AnalyticsInternal {
  if (client !== undefined) return client;

  try {
    const fullStoryMode = getBootFlag(BootFlags.FULL_STORY_MODE);

    let newClient: AnalyticsInternal;
    if (process.env.NODE_ENV === 'development') {
      newClient = new LocalDevAnalytics();
    } else if (fullStoryMode === 'enabled' || fullStoryMode === 'relayed') {
      const shouldUseRelay = fullStoryMode === 'relayed';
      newClient = new FullStoryAnalytics(shouldUseRelay);
    } else {
      newClient = new NoOpAnalytics();
    }

    newClient.initialize?.();

    // Cache the singleton instance
    client = newClient;
  } catch (err) {
    // Use the no-op client if something is wrong
    logError(err);
    client = new NoOpAnalytics();
  }

  return client;
}

import { AuthenticationContextProvider } from '@chronosphereio/core';
import { AuthRoutes } from '@/model/Routes';
import { fetch } from '@/utils';

/**
 * A small wrapper around the `AuthenticationContextProvider` in our `core` package which injects some dependencies that
 * still live in the `cloud` package via props.
 *
 * TODO: Remove this component once these dependencies no longer live in `cloud`.
 */
export function CloudAuthProvider({ children }: { children?: React.ReactNode }) {
  return (
    <AuthenticationContextProvider fetchFn={fetch} loginRoute={AuthRoutes.LOGIN} authRoutes={AuthRoutes}>
      {children}
    </AuthenticationContextProvider>
  );
}

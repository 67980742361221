import { normalizeTimeZone } from '@chronosphereio/core';
import constate from 'constate';
import { useState, useCallback, useEffect } from 'react';
import { useGetUserTimeZone, useSetUserTimeZone } from '@/model/user-settings';

function useUserTimeZoneStore({ defaultTimeZone }: { defaultTimeZone?: string }) {
  const [timeZone, setTimeZoneState] = useState<string>(normalizeTimeZone(defaultTimeZone));

  const { timeZoneSetting, loading } = useGetUserTimeZone();
  const setUserTimeZone = useSetUserTimeZone();

  useEffect(() => {
    if (defaultTimeZone) {
      // provide defaultTimeZone when testing the app, in which case we will not load timeZone from grafana
      setTimeZoneState(defaultTimeZone);
    } else if (timeZoneSetting && !loading) {
      // Initialize time zone from user settings.
      setTimeZoneState(timeZoneSetting);
    }
  }, [defaultTimeZone, loading, timeZoneSetting]);

  const setTimeZone = useCallback(
    async (newTimeZone: string) => {
      const prevTimeZone = timeZone;
      try {
        // Save local state.
        setTimeZoneState(newTimeZone);
        // Persist state to user settings.
        await setUserTimeZone(newTimeZone);
      } catch (e) {
        setTimeZoneState(prevTimeZone);
        await setUserTimeZone(prevTimeZone || '');
        throw e;
      }
    },
    [setUserTimeZone, timeZone]
  );

  return {
    timeZone,
    setTimeZone,
  };
}

export const [UserTimeZoneProvider, useUserTimeZone] = constate(useUserTimeZoneStore);

import Cookies from 'universal-cookie';
import {
  NotAuthenticatedError,
  useAuth,
  AuthenticationContextValue,
  SCOPES,
  CLI_SCOPES,
  useSessionStorage,
  NONCE_COOKIE_NAME,
  randomString,
} from '@chronosphereio/core';
import { Location, NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { getPostLoginRedirectUrl, POST_LOGIN_REDIRECT_STORAGE_KEY, LoginFormValues } from './login-model';
import { fetch } from '@/utils';
import { AuthRoutes } from '@/model/Routes';

/**
 * Error thrown when user/pass authentication fails due to bad credentials provided
 * by the user.
 */
export class InvalidCredentialsError extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, InvalidCredentialsError.prototype);
  }
}

export type LoginMethods = {
  isLoading: boolean;
  loginWithSso?: (scopes?: string[], state?: string) => void;
  loginWithPassword?: (formValues: LoginFormValues, state?: string) => Promise<void>;
};

type LoginClient = Pick<LoginMethods, 'loginWithSso' | 'loginWithPassword'>;

/**
 * Hook that returns methods for logging in.
 */
export function useLoginMethods(fromCli = false): LoginMethods {
  const authContext = useAuth();
  const { config, isLoading } = authContext;

  const location = useLocation();
  const navigate = useNavigate();
  const [, setRedirectTo] = useSessionStorage<string | undefined>(POST_LOGIN_REDIRECT_STORAGE_KEY, undefined);

  // Pick implementation to use based on type from config
  let client: LoginClient | undefined;
  if (config?.type === 'okta') {
    client = getOktaClient(authContext, location, setRedirectTo, false, fromCli);
  } else if (config?.type === 'fakeauth') {
    client = getFakeAuthClient(location, navigate, setRedirectTo);
  } else if (config?.type === undefined) {
    client = undefined;
  } else {
    throw new Error(`Unknown auth config type '${config?.type}'`);
  }

  // Only return methods that are enabled by configuration
  return {
    isLoading,
    loginWithSso: config?.allow_single_sign_on === true ? client?.loginWithSso : undefined,
    loginWithPassword: config?.allow_username_password === true ? client?.loginWithPassword : undefined,
  };
}

/**
 * Hook that returns methods for logging into the admin impersonate endpoints.
 */
export function useAdminLoginMethods(fromCli = false): LoginMethods {
  const authContext = useAuth();
  const { config, isLoading } = authContext;

  const location = useLocation();
  const navigate = useNavigate();
  const [, setRedirectTo] = useSessionStorage<string | undefined>(POST_LOGIN_REDIRECT_STORAGE_KEY, undefined);
  // Pick implementation to use based on type from config
  let client: LoginClient | undefined;
  if (config?.type === 'okta') {
    client = getOktaClient(authContext, location, setRedirectTo, true, fromCli);
  } else if (config?.type === 'fakeauth') {
    client = getFakeAuthClient(location, navigate, setRedirectTo);
  } else if (config?.type === undefined) {
    client = undefined;
  } else {
    throw new Error(`Unknown auth config type '${config?.type}'`);
  }

  // Only return methods that are enabled by configuration
  return {
    isLoading,
    loginWithSso: client?.loginWithSso,
    loginWithPassword: undefined,
  };
}

function getOktaClient(
  authContext: AuthenticationContextValue,
  location: Location,
  setRedirectTo: (url?: string) => void,
  useImpersonateClient = false,
  fromCli = false
): LoginClient {
  const {
    isLoading,
    config,
    oktaClient,
    oktaImpersonateClient,
    oktaImpersonateConfig,
    oktaCliClient,
    oktaCliImpersonateClient,
  } = authContext;

  // in unit tests the hook is called before auth context is loaded
  if (isLoading) {
    return {};
  }

  // Does SSO login by redirecting to Okta
  const loginWithSso = (scopes?: string[], state?: string) => {
    if (oktaClient === undefined || oktaCliClient === undefined) {
      throw new Error('No okta client available for SSO login');
    }

    if (useImpersonateClient && (oktaImpersonateClient === undefined || oktaCliImpersonateClient === undefined)) {
      throw new Error('No okta impersonate client available for SSO login');
    }

    if (config === undefined) {
      throw new Error('No auth config available for SSO login');
    }

    if (useImpersonateClient && (oktaImpersonateConfig === undefined || oktaCliImpersonateClient === undefined)) {
      throw new Error('No impersonate auth config available for SSO login');
    }

    setRedirectTo(getPostLoginRedirectUrl(location));

    const nonce = generateAndStoreNonce();
    if (
      useImpersonateClient &&
      oktaImpersonateClient !== undefined &&
      oktaCliImpersonateClient !== undefined &&
      oktaImpersonateConfig !== undefined
    ) {
      if (fromCli) {
        oktaCliImpersonateClient.token.getWithRedirect({
          responseType: 'code',
          scopes: scopes ? CLI_SCOPES.concat(scopes) : CLI_SCOPES,
          idp: oktaImpersonateConfig.okta.idp,
          nonce: nonce,
          state,
        });
      } else {
        oktaImpersonateClient.token.getWithRedirect({
          responseType: 'code',
          scopes: scopes ? SCOPES.concat(scopes) : SCOPES,
          idp: oktaImpersonateConfig.okta.idp,
          nonce: nonce,
          state,
        });
      }
    } else {
      if (fromCli) {
        oktaCliClient.token.getWithRedirect({
          responseType: 'code',
          scopes: CLI_SCOPES,
          idp: config.okta.idp,
          nonce: nonce,
          state,
        });
      } else {
        oktaClient.token.getWithRedirect({
          responseType: 'code',
          scopes: SCOPES,
          idp: config.okta.idp,
          nonce: nonce,
          state,
        });
      }
    }
  };

  // Does login with email/password using Okta API to check credentials, then redirecting to Okta
  const loginWithPassword: LoginMethods['loginWithPassword'] = async (formValues, state?: string) => {
    if (oktaClient === undefined || oktaCliClient === undefined) {
      throw new Error('No okta client available for user/pass login');
    }

    let sessionToken;
    try {
      // Hit the Okta Authn endpoint to validate creds and get a session token
      const res = await oktaClient.signIn({
        username: formValues.email,
        password: formValues.password,
      });
      sessionToken = res.sessionToken;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      // This code is returned when credentials are incorrect
      if (err.errorCode === 'E0000004') {
        throw new InvalidCredentialsError('Authentication failed, invalid credentials');
      }

      throw err;
    }

    const nonce = generateAndStoreNonce();
    setRedirectTo(getPostLoginRedirectUrl(location));

    // Redirect to Okta to use the session token
    if (fromCli) {
      oktaCliClient.token.getWithRedirect({
        sessionToken: sessionToken,
        responseType: 'code',
        scopes: CLI_SCOPES,
        nonce: nonce,
        state,
      });
    } else {
      oktaClient.token.getWithRedirect({
        sessionToken: sessionToken,
        responseType: 'code',
        scopes: SCOPES,
        nonce: nonce,
        state,
      });
    }
  };

  return {
    loginWithSso,
    loginWithPassword,
  };
}

function getFakeAuthClient(
  location: Location,
  navigate: NavigateFunction,
  setRedirectTo: (url?: string) => void
): LoginClient {
  // UAT environments have a fake login provider instead of Okta
  const loginWithPassword: LoginMethods['loginWithPassword'] = async (formValues) => {
    generateAndStoreNonce();

    try {
      const res = await fetch('/fakeauth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formValues),
      });

      const { session_token: sessionToken } = await res.json();
      setRedirectTo(getPostLoginRedirectUrl(location));
      navigate(`${AuthRoutes.LOGIN_CALLBACK}?code=${sessionToken}`);
    } catch (err) {
      // Fake auth returns a 401 when credentials are bad
      if (err instanceof NotAuthenticatedError) {
        throw new InvalidCredentialsError('Authentication failed, invalid credentials');
      }

      throw err;
    }
  };

  return {
    loginWithSso: undefined,
    loginWithPassword,
  };
}

function generateAndStoreNonce(): string {
  const cookies = new Cookies();
  const nonce = randomString(32, 'abcdefghijklmnopqrstuvwxyz0123456789');
  cookies.set(NONCE_COOKIE_NAME, nonce, {
    path: '/',
    secure: true,
  });
  return nonce;
}
